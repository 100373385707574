<template>
  <div class="databaseCard">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          {{title}}
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div class="card-content">
        <div class="content">
         {{description}}
        </div>
      </div>
      <footer class="card-footer">
        <a href="#" @click="promptAreYouSure" class="card-footer-item" v-if="!prompt && !loading && type == 'function'">Start</a>
        <router-link :to="slug" class="card-footer-item" v-else-if="type == 'redirect'">Start</router-link>
        <button @click="sync" class="button card-footer-item is-danger" v-else-if="prompt && !loading">Click Again to Confirm</button>
        <div class="eloader" style="width: 0px; height: 0px;">
          <Loader :isActive="loading"></Loader>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Databases from "@/api/Databases"
import Loader from "@/components/global/Loader"

export default {
  name: 'DatabaseCard',
  props: {
    title: String,
    description: String,
    howItSyncs: String,
    slug: String,
    type: String
  },
  components: {
    Loader
  },
  data() {
    return {
      prompt: false,
      loading: false
    }
  },
  methods: {
    promptAreYouSure() {
      this.prompt = true
      setTimeout(this.reset, 5000)
    },
    reset() {
      this.prompt = false
    },
    sync() {
      this.prompt = false
      this.loading = true
      console.log("Requesting synchronization of " + this.slug)
      Databases.syncDatabase(this.slug).then(this.processResponse)
    },
    processResponse(response) {
      console.log(response)
      this.loading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .eloader {
    width: 100%;
    height: 50px;
    display: flex; 
    align-items: center;
    justify-content: center;
  }
</style>
