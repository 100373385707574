<template>
  <div class="WMLoader">
    <div :class="'loader-wrapper' + (isActive? ' is-active' : '')">
      <div class="loader is-loading"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WMLoader',
  props: {
    isActive: false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$fade-grey: #ededed;
$grey: red;
$muted-grey: #999;
$heart: #ff4f8f;
$white: #fff;

.WMLoader {
  position: relative;
  width: 100%;
  height: 100%;
}

//LOADER STYLES
.loader-wrapper {

  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: .25s ease;

  .loader {
    height: 80px;
    width: 80px;
  }
  
  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}

.is-loading {
  position: relative;
}
</style>
