<template>
  <div class="webhooks">
    <div v-if="webhooks && webhooks.live">
      <div class="columns">
        <div class="column">
          <h1 class="title">Webhooks</h1>
        </div>
        <div class="column" style="display: flex; justify-content: flex-end;">
          <button class="button" @click="modalOpen = true">Reset Webhooks</button>
        </div>
      </div>

      <table class="table is-fullwidth">
        <thead>
          <th>Name</th>
          <th>Topic</th>
          <th>Status</th>
        </thead>
        <tbody>
          <tr v-for="webhook in webhooks.live">
            <td>{{webhook.name}}</td>
            <td>{{webhook.topic}}</td>
            <td>{{webhook.status}}</td>
          </tr>
        </tbody>
      </table>
      <ResetWebhooksModal :open="modalOpen" @close="closeModal"></ResetWebhooksModal>
    </div>
    <div style="position: relative; width: 100%; height: 100%;" v-else>
      <Loader is-active="true"></Loader>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Loader from "@/components/global/Loader"
import ResetWebhooksModal from "@/components/dashboard/admin/webhooks/ResetWebhooksModal"

export default {
  name: 'Webhooks',
  data() {
    return {
      modalOpen: false
    }
  },
  components: {
    Loader,
    ResetWebhooksModal
  },
  computed: {
     ...mapGetters({
      webhooks: "Webhooks/getWebhooks",
    }),
  },
  methods: {
    closeModal() {
      this.modalOpen = false
    }
  },
  mounted: function() {
    this.$store.dispatch("Webhooks/setWebhooks")
  }
}
</script>
<style scoped>
  .webhooks {
    height: 100%;
  }
</style>