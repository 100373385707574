<template>
  <div class="databases">
    <h1 class="title">Database Management</h1>
    <div class="columns">
      <div class="column is-one-quarter">
        <DatabaseCard title="Products & Listings" slug="/dashboard/databases/rebuild/products" type="redirect" description="Completely rebuild products and listing databases using WooCommerce database"></DatabaseCard>
      </div>
      <div class="column is-one-quarter" v-for="database in databases" v-bind:key="database.slug">

        <DatabaseCard :title="database.title" :slug="database.slug" type="function" :description="database.description"></DatabaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import DatabaseCard from "@/components/dashboard/databases/DatabaseCard"

export default {
  name: 'Databases',
  components: {
    DatabaseCard
  },
  data() {
    return {
      databases: [
        {
          title: "Product Categories",
          slug: "product_listing_categories",
          description: "Stores current WooCommerce product categories used by the website. This database requires manual syncing as there is no webhook available for product categories"
        },
      ]
    }
  }
}
</script>
