<template>
  <div class="rebuildProducts">
    <h1 class="title">{{title}}</h1>
    <div v-if="!started">
      <p>This process rebuilds the databases nessecary to host listings, products, and more. Affected tables: <b>woo_products</b>, <b>products</b>, <b>listings</b></p>
      <button @click="start" class="button is-primary">Start Rebuilding</button>
    </div>
    <div v-else-if="started && !finished"> 
        <ul class="steps is-vertical">
          <li :class="'steps-segment' + (step == 1? ' is-active':'')">
            <span href="#" class="steps-marker"></span>
            <div class="steps-content">
              <p class="is-size-4">Getting Products</p>
              <p v-if="step == 1">Get{{step == 1? 'ting' : ''}} all products currently stored in WooCommerce...</p>
              <div v-if="step == 1" style="margin-top: 5px;">
                <progress class="progress is-small is-primary" max="100" v-if="totalProductsInWooCommerce <= 0"></progress>
                <progress class="progress is-small is-primary" :value="totalWooCommerceProductsReceived" :max="totalProductsInWooCommerce" v-else></progress>
              </div>
            </div>
          </li>
          <li :class="'steps-segment' + (step == 2? ' is-active':'')">
            <span href="#" class="steps-marker"></span>
            <div class="steps-content">
              <p class="is-size-4">Upload{{step == 2? 'ing' : ''}} to <b>products</b> database</p>
              <p v-if="step == 2">The <b>products</b> database stores generalized product data used by Web Manager</p>
            </div>
          </li>
          <li :class="'steps-segment' + (step == 3? ' is-active':'')">
            <span class="steps-marker"></span>
            <div class="steps-content">
              <p class="is-size-4">Get{{step == 3? 'ting' : ''}} product variations</p>
              <p v-if="step == 3">There are {{productsWithVariations.length}} products with variations. Currently collected {{variations.length}} out of {{totalVariations}}</p>
            </div>
          </li>
        </ul>
    </div>

    <div v-else-if="finished">
      <h1 class="title">Database Rebuild Complete</h1>
    </div>
  </div>
</template>

<script>
import Products from "@/api/ProductListings"

export default {
  name: 'Rebuild Products',
  data() {
    return {
      title: "Rebuild Product Databases",
      started: false,
      finished: false,
      step: 1,
      wooProducts: [],
      totalProductsInWooCommerce: 0,
      totalProductPages: 0,
      currentProductPage: 0,
      totalWooCommerceProductsReceived: 0,
      variations: [],
      productsWithVariations: [],
      variationChunks: [],
      currentVariationChunk: -1,
      totalVariations: 0
    }
  },
  methods: {
    start() {
      this.started = true
      this.title = "Rebuilding Product Databases..."
      this.getNextPageFromWooCommerce()
    },
    getNextPageFromWooCommerce() {
      if(this.currentProductPage <= this.totalProductPages) {
        this.currentProductPage++
        Products.getAllFromWooCommerce({page: this.currentProductPage}).then(this.receivePageFromWooCommerce)
      } else {
        this.uploadToProducts()
      }
    },
    receivePageFromWooCommerce(response) {
      console.log("INIT RESPONSE", response)
      var headers = response.data.headers
      var products = response.data.data

      this.totalProductsInWooCommerce = headers["x-wp-total"]
      this.totalProductPages = headers["x-wp-totalpages"]

      for(var i = 0; i < products.length; i++) {
        var product = products[i]
        this.wooProducts.push(product)

        if(product.variations && product.variations.length > 0) {
          this.productsWithVariations.push(product.id)
          this.totalVariations += product.variations.length
        }
        this.totalWooCommerceProductsReceived++
      }

      this.getNextPageFromWooCommerce()
    },
    uploadToProducts() {
      this.step = 2
      console.log("ALL PRODUCTS", this.wooProducts)
      Products.uploadToProducts(this.wooProducts).then(this.done).catch((error) => {
        console.log("Error uploading to products", error)
      })

    },
    getVariations() {
      this.step = 3
      var chunks = []
      var IDS = this.productsWithVariations

      var size = 20;
      for (var i = 0; i < IDS.length; i += size) {
          this.variationChunks.push(IDS.slice(i, i + size))
      }

      this.nextVariationChunk()
    },
    nextVariationChunk() {
      if(this.currentVariationChunk <= this.variationChunks.length) {
        this.currentVariationChunk++
        this.processChunk(this.variationChunks[this.currentVariationChunk])
      } else {
        this.done()
      }
    },
    processChunk(chunk) {
      var promises = []

      chunk.forEach(ID => {
        promises.push(Products.getVariations(ID))
      })

      Promise.all(promises).then(this.receiveVariations).catch(error => {
        console.log("ERROR GETTING VARIATIONS", error)
        this.nextVariationChunk()
      })

    },
    receiveVariations(responses) {
      responses.forEach(response => {
        if(response.data && Array.isArray(response.data)) {
          response.data.forEach(product => {
            this.variations.push(product)
          })
        } else if(Array.isArray(response)){
          response.forEach(product => {
            this.variations.push(product)
          })
        } else {
          console.log("SKIPPED", response)
        }

      })

      this.nextVariationChunk()
    },
    done() {
      console.log(this.variations)
      this.finished = true
    }
  }
}
</script>
<style scoped>
  .steps-segment * {
    transition: .25s ease;
  }

  .steps-segment .steps-content {
    opacity: .25;
  }

  .steps-segment.is-active .steps-content {
    opacity: 1;
  }
</style>
