<template>
  <div class="resetWebhooksModal">
    <div :class="'modal ' + (open? 'is-active': '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Reset Webhooks</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label>Base URL</label>
            <input type="text" class="input" v-model="baseURL">
            <small style="font-size: 12px;"><i>{{baseURL}}/webmanager-ea6a8/us-central1/webhooks-product-update</i></small>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="resetWebhooks" :disabled="canPublish">Publish</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Webhooks from "@/api/Webhooks"

export default {
  name: 'resetWebhooksModal',
  props: {
    open: Boolean
  },
  data() {
    return {
      baseURL: ""
    } 
  },
  computed: {
    canPublish() {
      return this.baseURL.length < 8
    }
  },
  watch: {
    "baseURL": function() {
      this.baseURL = this.baseURL.trim()
    }
  },
  methods: {
    close(response) {
      if(response) {
        console.log("Closing modal with response", response)
      }
      this.$emit('close')
    },
    resetWebhooks() {
      console.log("Resetting webhooks...", this.baseURL)
      Webhooks.resetWebhooks(this.baseURL).then(this.close).catch(this.close)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
